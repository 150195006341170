import { Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { contentProps } from './contentProps'
import { config } from 'config'
import theme from 'ui/settings/theme'

export const PopupActionEnd = ({ isOpened, onClose }) => (
  <PopupInfo isOpened={isOpened} onClose={onClose}>
    {config.texts.popups.actionEnd.heading && (
      <Text {...contentProps}>{config.texts.popups.actionEnd.heading}</Text>
    )}
    {config.texts.popups.actionEnd.subheading && (
      <Text
        mb="20px"
        fontSize="16px"
        fontWeight="500"
        lineHeight="130%"
        letterSpacing="0.5px"
        textAlign="center"
        color={theme.colors.white}
      >
        {config.texts.popups.actionEnd.subheading}
      </Text>
    )}
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupInfo>
)
